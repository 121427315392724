.app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
.event-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 0 80px ;
    background-color: #F5E9DE;
    background-image: url('../src/assets/images/bgc.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title-main {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 32px;
    font-weight: 700;
    color: #ce1c19;
    text-align: center;
    letter-spacing: 2px;
  }
  
  .title-sub {
    margin-bottom: 40px;
    font-size: 1.1em;
    font-weight: 700;
    color: #ce1c19;
    text-align: center;
  }
  
  .title-congrats {
    margin: 0;
    margin-bottom: 16px;
    font-size: 1.4em;
    font-weight: bold;
    color: #533a31;
    text-align: center;
  }
  
  /* 折抵券 */
  .coupon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .coupon {
    position: relative; 
    display: flex;
    flex-direction: column; 
    width: 256px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
  }
  
  /* 折抵券內容區塊 */
  .coupon-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    height: 176px;
    text-align: center;
    background-color: #ce1c19;
  }
  
  .coupon-content::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    box-sizing: border-box;
    width: 216px;
    height: 144px;
    content: "";
    border: 2px solid #fff;
    border-radius: 8px;
    transform: translate(-50%, -50%);
  }
  
  /* code128條碼區塊 */
  .barcode-svg {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px;
    overflow: hidden; 
  }
  
  .barcode-svg svg {
    max-width: 100%; 
    height: auto; 
    overflow: hidden; 
  }
  
  /* 條碼區塊 */
  .barcode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: #fff;
  }
  
  .barcode::before {
    display: block;
    width:80%;
    height: 54%; 
    content: "";
    background: repeating-linear-gradient(
      to left,
      #000 0,
      #000 5px,
      #fff 5px,
      #fff 10px
    );
  }
  
  /* 折抵券名稱字型 */
  .coupon-name {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
  }
  
  /* iRent序號字型 */
  .coupon-irent-code {
    margin-top: 8px;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
  }
  
  .coupon-irent-code span {
    padding: 0 10px;
    font-weight: bold;
    color: #e32c29;
    background: #fff;
    border-radius: 4px;
  }
  
  /* 折抵券補充說明 */
  .coupon-description {
    margin-top: 20px;
    font-size: 14px;
    color: #533a31;
    text-align: center;
  }
  
  /* 銘謝惠顧字體大小 */
  .thanks {
    font-size: 32px;
  }
  
  .thanks-description {
    font-size: 16px;
    letter-spacing: 0.4px;
  }
  
  /* footer注意事項 */
  .footer {
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 120px;
    margin: 0;
    text-align: center;
    background-color: #322929;
  }
  
  .footer-content {
    max-width: 640px;
    margin: 0 auto;
    text-align: left;
  }
  
  .footer h3 {
    padding-bottom: 8px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  
  .footer hr {
    height: 2px;
    margin: 40px 0;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
  }
  
  .footer ul {
    padding-left: 20px; 
    margin: 0;
    font-size: 16px;
    line-height: 1.6; 
    color: rgba(255, 255, 255, 0.8);
  }
  
  .footer li {
    margin-bottom: 8px;
  }
  