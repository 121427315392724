/* 基本設置 */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Verdana, 'PingFang TC', 'Microsoft JhengHei', sans-serif;
}

/* 背景與容器設置 */
html, body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}
