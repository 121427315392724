/* 容器設置 */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

/* 刮刮樂卡片設置 */
.scratch-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 310px;
  overflow: hidden;
  background: linear-gradient(180deg, #ff5350 0%, #d82f2c 100%);
  border-radius: 8px;
}

/* 金幣圖片分佈設置 */
.coin {
  position: absolute;
}

.coin1 {
  top: -2%;
  left: 80%;
  transform: rotate(-12deg);
}

.coin2 {
  top: 4%;
  left: 12%;
  transform: rotate(24deg);
}

.coin3 {
  top: 38%;
  left: 93%;
  transform: rotate(12deg);
}

.coin4 {
  top: 70%;
  left: -2%;
  transform: rotate(-20deg);
}

.coin5 {
  top: 94%;
  left: 30%;
  transform: rotate(12deg);
}

.coin6 {
  top: 91%;
  left: 84%;
  transform: rotate(-20deg);
}

/* 閃爍圖片分佈設置 */
.spark {
  position: absolute;
}

.spark1 {
  top: -4%;
  left: 40%;
}

.spark2 {
  top: 8%;
  left: 86%;
}

.spark3 {
  top: 15%;
  left: -4%;
}

.spark4 {
  top: 48%;
  left: 1%;
}

.spark5 {
  top: 70%;
  left: 94%;
}

.spark6 {
  top: 88%;
  left: 60%;
}

.spark7 {
  top: 94%;
  left: 5%;
}

/* 刮刮樂描述區域 */
.scratch-card__content {
  position: relative;
  margin: 40px 0 20px;
  overflow: hidden;
  font-size: 24px;
  text-align: center;
}

.scratch-card__sub-title {
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  letter-spacing: 2px;
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: #ffe581;
  text-shadow: -2px -2px 0 #db312e, 2px -2px 0 #db312e, -2px 2px 0 #db312e, 2px 2px 0 #db312e;
  letter-spacing: 4px;
}

/* 刮刮樂區域 */
.scratch-card__area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 144px;
  margin: 0 auto 40px;
  overflow: hidden;
  background-color: #f4f4f4;
  border: 4px solid #ffe581;
  border-radius: 8px;
}

/* 灰色背景層 */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(196, 196, 196, 1);
}

.scratch-card__mask-text {
  z-index: 2;
  font-size: 22px;
  color: #fff;
  letter-spacing: 2px;
}

/* 刮刮樂結果 */
.scratch-card__message {
  z-index: 1;
  font-size: 16px;
  color: #333;
  text-align: center;
}

/* 折抵卷 */
.scratch-card__coupon-title {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #515151;
}

.scratch-card__coupon-content {
  margin-bottom: 4px;
  font-size: 14px;
  color: #515151;
}

.scratch-card__coupon-item {
  font-size: 16px;
  font-weight: 500;
  color: #eb403d;
}

hr {
  margin: 8px 0;
  border: none;
  border-top: 2px solid #e6e6e6;
}

/* 煙火動畫 */
.scratch-card__firework-animation {
  position: absolute;
  top: 58%;
  left: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  pointer-events: none;
  transform: translate(-50%, -58%);
}
